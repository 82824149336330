import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import MasterService from "@/core/services/master.service";

export default {
  components: {
    // Dropdown1
  },

  computed: {
    ...mapGetters([
      "currentUser",
      "currentYear"
    ])
  },

  data() {
    return {
      // :core
      dataUser: {},
      searchMain: {},
      // :end core

      // :last 10 transaction
      tContents: [],
      tContentsTotal: 1,
      tContentsHeader: [
        { text: 'Nama Lengkap', sortable: false, align: 'start', value: 'name'},
        { text: 'Username', sortable: false, align: 'start', value: 'username'},
        { text: 'Email', sortable: false, align: 'start', value: 'email'},
        { text: 'Status', sortable: false, align: 'start', value: 'status'},
        { text: 'Role', sortable: false, align: 'start', value: 'role_nama'},
        { text: 'Actions', sortable: false, align: 'right', value: 'actions'},
      ],
      tContentsOptions: {},
      tContentsLoading: false,
      tContentsDisabled: false,
      tContentsPaginations: [5,10,15,25,50,100],
      tContentsSearch: {},
      // :end last 10 transaction

      // :additional
      title: "User",

      modalDate1: false,
      modalDate2: false,
      modal: false,
      modalChangePassword: false,
      
      employee_id: null,
      
      form: {},
      formTitle: '',
      formLoading: false,
      formValid: true,
      
      loadingUsers: false,
      dataUsers: [],
      searchUsers: null,
      searchSelectedUsers: 'employee_name',
      searchTimeout: null,

      dataRole: [],
      loadingRole: false,
      dataRoleUser: [],
      loadingRoleUser: false,
      dataBranch: [],
      loadingBranch: false,
      dataAtasan: [],
      loadingAtasan: false,


      isCurrentPass: String,
      isNewPass: String,
      isConfirmPass: String,
      
      SearchJamaah : null,
      getJamaahSearch: [],
      getJamaahSearchLoading: false,
      
      // :validation
      commonValidation: [v => !!v || 'This field is required'],
      radioValidation: [v => v != null || 'This field is required'],
      emailValidation: [
        v => !!v || 'This field is required',
        v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid'
      ]
      // :end validation
    };
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Payment", route: "/" },
      { title: "Users List" }
    ]),

    this.dataUser = this.currentUser;
    this.searchMain.tahun = this.currentYear;
    this.searchMain.admin_id = Number(this.currentUser.id);

    
    this.loadRole();
    this.loadAtasan();
    this.loadRoleUser();
  },

  watch: {
    tContentsOptions: {
      handler () {
        this.loadMainContent();
      },
      deep: true,
    },

    searchUsers(val) {
      if(!val) return
      if(val.length < 2) return

      if(this.searchTimeout) clearTimeout(this.searchTimeout);

      this.searchTimeout = setTimeout(() => {
        this.loadingUsers = true;

        ApiService.get("autocomplete", "users-employee?limit=50&type="+this.searchSelectedUsers+"&"+this.searchSelectedUsers+"="+val)
        .then((data)=>{
          this.loadingUsers = false;
          if(data.data.detail){
            this.dataUsers = data.data.detail;
          }else{
            this.dataUsers = [];
          }
        })
        .catch(() => {
          this.dataUsers = [];
          this.loadingUsers = false;
        })
      }, 1000);
    },
    async SearchJamaah(val) {
      if (this.getJamaahSearchLoading) return
      this.getJamaahSearchLoading = true;
      this.getJamaahSearch = await this.getJamaah("jamaah?src="+val);
      this.getJamaahSearchLoading = false;
    },
  },

  methods: {
    async getJamaah(url) {
      ApiService.setHeader();
      return await ApiService.get("autocomplete", url).then(res => {
          return res.data.data; 
      }).catch(err =>{ 
          this.$swal("Failed", this.$helpers.getTextAlert(err), "error")
          return []; 
      });
    },
    getMainList() {
      return new Promise((resolve, reject) => {
        const { sortBy, sortDesc, page, itemsPerPage } = this.tContentsOptions;
        
        let dataSort = {};
        let isSort = sortDesc.length;
        if(isSort > 0){
          for(let i=0; i<isSort; i++){
            let item1 = sortBy[i];
            let item2 = sortDesc[i];
            
            if(item2 == true){
              dataSort[item1] = "DESC";
            }else{
              dataSort[item1] = "ASC";
            } 
          }
        }

        dataSort["id"] = "DESC";
        
        var query = { 
          start: (page - 1) * itemsPerPage,
          length: itemsPerPage,
          order: dataSort,
          main: this.searchMain,
          search: this.tContentsSearch
        }

        ApiService.post("user/list", query, 1).then(({ data }) => {
          resolve(data)
        }).catch(({ response }) => {
          reject(response);
        })

      })
    },
    
    changeSelectedUsers(){
      console.log(this.searchSelectedUsers);
    },

     showModalUser(content){
      if(this.$refs.vForm) this.$refs.vForm.reset()
      
      setTimeout( async()  => {
        this.form = {};
        if(content != null){
          this.formTitle = "Edit";
          this.form = { ...content };
        
        }else{
          this.formTitle = "Add";
        }
        this.modal = true;
      }, 10);
    },

    showModalUserPassword(content){
      if(this.$refs.vFormPassword) this.$refs.vFormPassword.reset()
      
      this.form = {};
      setTimeout(() => {
        this.form.id = content.id;
        this.modalChangePassword = true;
      }, 10);
    },

    submitForm(){
      if(!this.$refs.vForm.validate()) return

      const id = this.form.id;
      let url = 'create';
      let msg = 'Tambah';
      this.searchMain.id = null;

      if(id != undefined){
        url = 'update';
        msg = 'Ubah';
        this.searchMain.id = this.form.id;
      }

      this.$swal({
        title: 'Peringatan',
        html: 'Apakah anda yakin ingin <strong>'+msg+'</strong> user ini ?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: "Submit"
      }).then((result) => {
        if(result.isConfirmed){
          var form = { ...this.form };
          delete form.created_at;
          delete form.created_by;
          delete form.modified_at;
          delete form.modified_by;
          
          if(form.is_active) form.is_active = 1;
          else form.is_active = 0;

          var query = {
            main: this.searchMain,
            data: { ...form }
          }

          this.formLoading = true;
          ApiService.post("user/"+url, query, 1).then(() => {
            this.formLoading = false;
            this.$swal("Berhasil", "Data berhasil di "+msg, "success");
            this.loadMainContent();
            this.modal = false;
          }).catch(({ response }) => {
            this.formLoading = false;
            this.$swal("Failed", this.$helpers.getTextAlert(response), "error");
          })
        }
      })
    },

    submitFormPassword(){
      if(!this.$refs.vFormPassword.validate()) return;
    
      this.$swal({
        title: 'Peringatan',
        html: 'Apakah anda yakin ingin ubah password user ini</strong> ?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: "Submit"
      }).then((result) => {
        if(result.isConfirmed){

          var query = {
            main: this.searchMain,
            data: this.form
          }

          this.formLoading = true;
          ApiService.post("user/update-password", query, 1).then(() => {
            this.formLoading = false;
            this.$swal("Berhasil", "Password berhasil di ubah", "success");
            this.loadMainContent();
            this.modalChangePassword = false;
          }).catch(({ response }) => {
            this.formLoading = false;
            this.$swal("Failed", this.$helpers.getTextAlert(response), "error");
          })
        }
      })
    },

    submitSearchTContents(){
      this.tContentsOptions.page = 1;
      this.loadMainContent();
    },

    deleteUser(item){
      
      this.$swal({
        title: 'Peringatan',
        html: 'Apakah anda yakin ingin hapus user <strong>'+item.name+'</strong> ?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: "Submit"
      }).then((result) => {
        if(result.isConfirmed){

          var query = {
            main: this.searchMain,
            data: {
              id: item.id
            }
          }

          this.formLoading = true;
          ApiService.post("user/delete", query, 1).then(() => {
            this.formLoading = false;
            this.loadMainContent();
            this.$swal("Berhasil", "User : <strong>"+item.name+"</strong> berhasil di hapus", "success");
          }).catch(({ response }) => {
            this.formLoading = false;
            this.$swal("Failed", this.$helpers.getTextAlert(response), "Failed");
          })
        }
      })
    },

    getDataEmployee(){
      let index = this.dataUsers.findIndex(res => res.employee_id === this.employee_id);
      let user = this.dataUsers[index];
      
      this.formAdd = {};
      this.formAdd = {...user};
      this.formAdd.is_active = true;
    },



    // :loader
    loadMainContent(){
      this.tContents = [];
      this.tContentsLoading = true;
      this.tContentsTotal = true;
      this.getMainList().then(data => {
        this.tContentsLoading = false;
        if(data.detail){
          this.tContents = data.detail
        }else{
          this.tContents = []
        }
        this.tContentsTotal = data.count
      }).catch(({ response })=>{
        this.tContents = []
        this.tContentsTotal = 0;
        this.tContentsLoading = false;
        this.$swal("Failed", response, "error");
      })
    },
    
    loadRole(){
      this.loadingRole = true;
      MasterService.loadMaster("dropdown", "role").then(({ data })=>{
        this.loadingRole = false;
        this.dataRole = data.data;
      }).catch((response)=>{
        this.loadingRole = false;
        this.$swal("Failed", this.$helpers.getTextAlert(response), "error");
      });
    },
    loadRoleUser(){
      this.loadingRole = true;
      MasterService.loadMaster("dropdown", "role_user").then(({ data })=>{
        this.loadingRoleUser = false;
        this.dataRoleUser = data.data;
      }).catch((response)=>{
        this.loadingRoleUser = false;
        this.$swal("Failed", this.$helpers.getTextAlert(response), "error");
      });
    },
    remove (item) {
      const index = this.form.role_id.indexOf(item.value)
      if (index >= 0) this.form.role_id.splice(index, 1)
    },

    loadAtasan(){
      this.loadingAtasan = true;
      MasterService.loadMaster("dropdown", "atasan").then(({ data })=>{
        this.loadingAtasan = false;
        this.dataAtasan = data.data;
      }).catch((response)=>{
        this.loadingAtasan = false;
        this.$swal("Failed", this.$helpers.getTextAlert(response), "error");
      });
    },
    // :end loader
  }
};